@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.article-audio-button-amp {
  align-items: center;
  border-radius: 100px;
  box-sizing: content-box;
  color: #3B3B3B;
  display: flex;
  flex-direction: row;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  gap: 6px;
  justify-content: center;
  line-height: 12px;
  max-height: 30px;
  max-width: 30px;
  padding: 8px 7.5px;
}

.article-audio-button-amp:active {
  background-color: #FEF4E6;
  border: 1px solid #FABB67;
}

.default {
  background-color: #FFFFFF;
  border: 1px solid #C2C2C2;
}

.play {
  background: #FBD299;
  border: 1px solid #FBD299;
}


.article-audio-icon-amp {
  height: 12px;
  width: 13px;
}

.article-audio-label-amp{
  width: 49px;
}